





























































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProjectViewModel } from '../viewmodel/project-viewmodel'

@Observer
@Component
export default class FundedProjectTable extends Vue {
  @Inject() vm!: ProjectViewModel
}
